import React, { Component } from 'react';

import API from './utils/api';

import Result from './components/Result/Result';
import Splash from './components/Splash/Splash';

class App extends Component {
  state = {
    loading: true,
    isLinkValid: false
  }

  async componentDidMount(){
    const url = window.location.pathname;
    const urlArray = url.split('/');
    const link = urlArray[urlArray.length -1];
    try {
      await API.post('/verifyEmail', { link });
      this.setState({ loading: false, isLinkValid: true });
    } catch (e) {
      this.setState({ loading: false, isLinkValid: false });
    }
  }
  
  render() {
    return this.state.loading ? <Splash/> : <Result valid={ this.state.isLinkValid } />;
  }
}


export default App;
