import React from 'react';

import Aux from '../../utils/auxiliary';
import { useCurrentWidth } from '../../utils/resize';
import './Result.scss';

export default props => {
  let width = useCurrentWidth();

  const valid = (
    <div className='childContent'>
      <h2 className='web'>
        Email vérifié
      </h2>
      <p className='description'>
        Parfait, tu peux maintenant retourner sur l’application Humoon pour finaliser ton parcours.
      </p>
      <img
        className='check'
        alt='success'
        src={require('../../assets/images/check.png')} />
    </div>
  );

  const invalid = (
    <div className='childContent'>
      <h2 className={width >= 416 ? 'web' : 'web colorWhite'}>
        Oups! Ce lien n'est pas valide.
      </h2>
    </div>
  );

  return (
    <Aux>
      {width >= 416 && (
        <div className={props.valid ? 'half-bg' : 'half-bg bgColor'}></div>
      )}
      <div className={width >= 416 ? 'slideContainer halfBg' : 
        (props.valid ? 'slideContainer validBgColor' : 'slideContainer invalidBgColor')}>
        <div className='logoContainer'>
          <img
            className='humoonLogo'
            alt='Humoon'
            src={props.valid ? 
              require('../../assets/humoon.png') : require('../../assets/humoon_white.png')
            }
          />
        </div>

        <div className='result-container'>
          <div className='slideBodyResult'>
            <div className='content'>
              { props.valid ? valid : invalid }
            </div>
          </div>

          {props.valid ? (
            <div className='motorcycle'>
              <img
                alt='Motorcycle'
                src={width >= 416 ? require('../../assets/images/motorcycle.png') : 
                  require('../../assets/images/smallMotorcycle.png')} />
            </div>
          ) : (
            <div className={width >= 416 ? 'hiddenPerson' : 'hiddenPerson smallHiddenPerson'}>
              <img
                alt='Hidden person'
                src={width >= 416 ? require('../../assets/images/hiddenPerson.png') : 
                  require('../../assets/images/smallHiddenPerson.png')} />
            </div>
          )}
        </div>
      </div>
    </Aux>
  );
}