import React from 'react';
import './Splash.scss';

export default () => {
  return (
    <div className='spinnerContainer'>
      <div className='spinContent'>
        <img 
          className='loadingLogo'
          alt='Humoon'
          src={require('../../assets/images/loadingLogo.png')} />
      </div>
    </div>
  );
}